export default function PagePrivacy() {
  return (
    <body className="flex items-center justify-center mb-[100px]">
      <div className="w-[800px] mt-[100px]">
        <p>
          <h1 className="text-3xl ">Art {`&`} ArtTech Privacy Policy</h1>
          <br />
          Art {`&`} ArtTech is the information subject(customer) in accordance
          with Article 30 of the Privacy Act, Protect your personal information
          and make any grievances related to it quickly and smoothly In order to
          ensure that it can be processed, the following personal information
          processing instructions are provided Establish and disclose.
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            1. Purpose of processing personal information
          </h2>
          <br />
          Art {`&`} ArtTech processes personal information for the following
          purposes It is not used for any purpose other than the following
          purposes.
          <br />
          – Confirmation of the customer's intention to sign up, identification
          of the customer in accordance with the provision of services to the
          customer Certification, maintenance and management of membership,
          supply of goods or services Payment of money, supply and delivery of
          goods or services, etc.
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            2. Processing and retention period of personal information
          </h2>
          <br />① Art {`&`} Art Tech has received consent when collecting
          personal information from the information subject Personal information
          in accordance with the period of retention and use of personal
          information or the law We process and retain personal information
          within the retention and use period.
          <br />
          <br />
          ② The specific processing and retention period of personal information
          is as follows:
          <br />
          <br />
          – Customer subscription and management: Until the termination of the
          service use agreement or membership However, in the event of a bond or
          debt relationship remaining, until the settlement of the bond or debt
          relationship
          <br />
          <br />
          - Record of contract, withdrawal of application, payment, supply of
          goods, etc. in e-commerce : 5 years
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            3. Provision of personal information to third parties
          </h2>
          <br />
          Art {`&`} ArtTech has the separate consent of the information subject,
          special provisions of the law Personal information except when it
          falls under Article 17 of the Personal Information Protection Act,
          etc. Do not provide it to third parties.
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            4. Rights,obligations and methods of exercise of information
            subjects and legal representatives
          </h2>
          <br />
          The subject of information shall, at any time, provide personal
          information of each of the following items for Art ${`&`} ArtTech You
          can exercise protection-related rights.
          <br />
          <br />
          1. Request to view personal information
          <br />
          <br />
          2. Request correction if there are errors in personal information
          <br />
          <br />
          3. I need to delete it.
          <br />
          <br />
          4. Processing Stop request
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            5. Personal Information items processed
          </h2>
          <br />
          Art {`&`} ArtTech processes the following personal information items.
          <br />
          <br />
          – Exhibition-related artist information such as name, mobile phone
          number, email address, etc.
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            6. Destruction of personal information
          </h2>
          <br />① Art {`&`} ArtTech has passed the period of retention of
          personal information and achieved the purpose of processing In the
          event that personal information becomes unnecessary, such as personal
          information, without delay, the relevant personal information Destroy.
          <br />
          <br />② Art {`&`} ArtTech will destroy personal information in the
          following ways.
          <br />
          <br />
          - Electronic files: Delete files and format storage media such as
          disks
          <br />
          <br />
          - Manual documents: Crushed or incinerated
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            7. Measures to ensure the safety of personal information
          </h2>
          <br />
          In order to ensure the safety of personal information, Art {`&`}{" "}
          ArtTECH has the following We are taking action.
          <br />
          <br />
          - Administrative measures: Establishment and implementation of
          internal management plans, employees,employees, etc. Regular training
          for
          <br />
          <br />
          - Technical measures : Personal information processing system (or
          personal information stored Management of access rights, such as
          password setting of computer), vaccine software Installation of
          security programs, encryption of files where personal information is
          stored, etc.
          <br />
          <br />
          - Physical measures : Time and access control of the place where
          personal information is stored and stored etc.
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            8. Installation,operation and denial of automatic collection of
            personal information
          </h2>
          <br />① Art {`&`} ArtTech provides individual customized services to
          users Use 'cookie'to save and load usage information from time to
          time.
          <br />
          <br />
          ② Cookies are used to operate the website by the server (http) of the
          user A small amount of information sent to the computer browser in the
          user's PC computer It is also stored on a hard disk.
          <br />
          <br />
          Go. Purpose of use of cookies: Each service and website you visit
          <br />
          <br />
          About the type of visit and use, popular search terms, security
          access, etc. Grasp
          <br />
          <br />
          Used to provide optimized information to users.
          <br />
          <br />
          Me. Installation, operation and rejection of cookies : Tools{`>`}
          Internet Options at the top of your web browser{`>`} To refuse to save
          cookies through the options settings in the Privacy menu can.
          <br />
          <br />
          c. If you refuse to store cookies, you will experience difficulties
          using customized services can.
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">
            9. Personal Information Protection Officer
          </h2>
          <br />
          Art {`&`} Art Tech is responsible for the processing of personal
          information, Handling complaints and damages of information subjects
          related to the processing of personal information In order to process
          the personal information protection officer as follows There are.
          <br />
          <br />
          ▶ Personal Information Protection Officer
          <br />
          <br />
          Name: Soomyoung Yi, Title: Representative
          <br />
          <br />
          Contacts : Email - services@artnartech.com
          <br />
          <br />
          <h2 className="text-xl mt-[50px]">10. Changes to Privacy Policy</h2>
          <br />
          This Privacy Policy is Applied from 2020. 8. 7.
        </p>
      </div>
    </body>
  );
}
