import React from "react";
import ReactDOM from "react-dom";

//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";

// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//i18n
import { IntlProvider } from "react-intl";
import koLang from "i18n/locale/ko.json";
import enLang from "i18n/locale/en.json";
import viLang from "i18n/locale/vi.json";
import deLang from "i18n/locale/de.json";
import jpLang from "i18n/locale/jp.json";

const locale = localStorage.getItem("locale") ?? "en";
const message = { en: enLang, ko: koLang, vi: viLang, de: deLang, jp: jpLang }[
  locale
];

ReactDOM.render(
  <IntlProvider locale={locale} messages={message} defaultLocale="en">
    <App />
  </IntlProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
