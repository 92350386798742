import React, { FC, useState, useEffect } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import GoogleMapReact from "google-map-react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Heading2 from "components/Heading/Heading2";
import RealEstateCardH from "components/RealEstateCardH/RealEstateCardH";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PrdType } from "data/types";
import axios from "axios";

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [perPageContent, setPerPageContent] = useState(10);
  const [ShowMoreLoading, setShowMoreLoading] = useState(false);
  const [data, setData] = useState<PrdType>();

  useEffect(() => {
    const storage = window.sessionStorage;
    if (storage.getItem("other-goods-id") || storage.getItem("cars-id")) {
      storage.removeItem("goods-current-content");
      storage.removeItem("car-current-content");
    }
    if (storage.getItem("estate-current-content")) {
      setPerPageContent(Number(storage.getItem("estate-current-content")));
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Product/GetPRDInfoAllPage`, {
        pageNo: 1,
        pageSize: perPageContent,
        lngcd: window.localStorage.getItem("locale"),
        category: "REAL000",
        // userId: userId ? userId : null,
        typeABS: "A",
        sort: "0",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.data[0].totalCnt === 0) {
          alert("Data is not exist");
          window.location.replace("/");
          return;
        } else {
          setData(response.data.data[0]);
        }
      })
      .catch(error => console.log(error));
  }, [perPageContent]);

  function loadMoreContent() {
    setShowMoreLoading(true);
    setPerPageContent(perPageContent + 10);
    setTimeout(() => {
      setShowMoreLoading(false);
    }, 1000);
  }
  function setPrdId(id: string) {
    window.sessionStorage.setItem("real-estate-id", String(id));
    window.sessionStorage.setItem(
      "estate-current-content",
      String(perPageContent)
    );
  }

  function scrollToTop() {
    window.scrollTo({
      top: 800,
      behavior: "smooth",
    });
  }
  return (
    <div>
      <div className="relative flex min-h-screen">
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading=""
            subHeading={
              <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                {data?.totalCnt} real estate
              </span>
            }
          />
          {/* <div className="mb-8 lg:mb-11">
          <TabFilters />
        </div> */}
          <div className="grid grid-cols-1 gap-8">
            {data?.productLists?.map(item => (
              <div
                key={item.prdInfoId}
                onClick={() => {
                  setPrdId(String(item.prdInfoId));
                }}
                onMouseEnter={() => setCurrentHoverID(_ => item.prdInfoId)}
                onMouseLeave={() => setCurrentHoverID(_ => -1)}
              >
                <RealEstateCardH data={item} />
              </div>
            ))}
            {perPageContent > Number(data?.totalCnt) ? null : (
              <ButtonSecondary
                onClick={loadMoreContent}
                loading={ShowMoreLoading ? true : false}
              >
                Show me more{" "}
              </ButtonSecondary>
            )}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <ButtonSecondary onClick={scrollToTop}>⬆️&nbsp;Top</ButtonSecondary>
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <>
          {data !== undefined ? (
            <div
              className={`xl:flex-grow xl:static xl:block ${
                showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
              }`}
            >
              {showFullMapFixed && (
                <ButtonClose
                  onClick={() => setShowFullMapFixed(false)}
                  className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                />
              )}

              <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
                <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
                  <Checkbox
                    className="text-xs xl:text-sm"
                    name="xx"
                    label="Search as I move the map"
                  />
                </div>
                {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}

                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
                  }}
                  defaultZoom={12}
                  yesIWantToUseGoogleMapApiInternals
                  defaultCenter={{
                    lat: data.productLists[0].geom_Lat,
                    lng: data.productLists[0].geom_Lon,
                  }}
                >
                  {data.productLists?.map((item, index) => (
                    <AnyReactComponent
                      isSelected={currentHoverID === item.prdInfoId}
                      key={index}
                      lat={item.geom_Lat}
                      lng={item.geom_Lon}
                      data={item}
                    />
                  ))}
                </GoogleMapReact>
              </div>
            </div>
          ) : null}
        </>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
