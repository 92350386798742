import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import facebookSvg from "images/Facebook.svg";
// import AppleSvg from "images/Apple.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { getCookie, setCookie } from "utils/cookies";
import { signInWithGoogle } from "../../firebase/firebase.utils.googleauth";
import { signInWithFacebook } from "../../firebase/firebase.utils.facebookauth";

export interface PageLoginProps {
  className?: string;
  onChange?: () => void;
  onClick?: () => void;
}
axios.defaults.withCredentials = false;

const validEmail = (e: string) => {
  const resurt =
    /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  return resurt.test(String(e).toLowerCase());
};

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  //   click: signInWithFacebook,
  // },
  // {
  //   name: "Continue with Apple",
  //   href: "#",
  //   icon: AppleSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
    click: signInWithGoogle,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  window.history.forward();
  if (window.location.href.includes("login") && getCookie("user-id")) {
    window.location.replace("/");
  }

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLogged, setIsLogged] = useState(0);

  useEffect(() => {
    if (isLogged === 200) {
      window.location.href = "/";
      return;
    } else if (
      window.location.href.includes("/login") &&
      window.sessionStorage.getItem("user-id") !== null
    ) {
      alert("Already Logged");
      window.location.href = "/";
      return;
    }
  });

  function userInputEmail(e: any) {
    setEmail(e.target.value);
  }

  function userInputPassword(e: any) {
    setPassword(e.target.value);
  }

  function userSignInButn() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Users/login`, {
        lngCd: window.localStorage.getItem("locale"),
        snsTypeCd: "0",
        email: email,
        password: password,
        snsUid: "",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.status === 200) {
          setCookie("access-token", response.data.data["key"]);
          setCookie("refresh-token", response.data.data["subKey"]);
          setCookie("user-id", response.data.data.userId);
          setIsLogged(response.data.status);
        } else if (response.data.status >= 400 && response.data.status < 500) {
          alert("Account invalid");
          return;
        } else {
          alert("Service Error");
          return;
        }
      })
      .catch(error => console.log(error));
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin h-100%">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                onClick={item.click}
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={userInputEmail}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-password" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                onChange={userInputPassword}
              />
            </label>
            <ButtonPrimary
              type="button"
              disabled={validEmail(email) && password.length > 7 ? false : true}
              onClick={userSignInButn}
              href={isLogged ? "/" : ""}
            >
              Continue
            </ButtonPrimary>
          </form>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" onClick={() => window.sessionStorage.clear()}>
              <u>Create an account</u>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
