import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { PrdList } from "data/types";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
//import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
// import Badge from "shared/Badge/Badge";

export interface OtherGoodsCardProps {
  className?: string;
  ratioClass?: string;
  data?: PrdList;
  size?: "default" | "small";
}

const OtherGoodsCard: FC<OtherGoodsCardProps> = ({
  size = "default",
  className = "",
  data,
  ratioClass,
}) => {
  function setAuthorId() {
    window.sessionStorage.setItem("author-id", String(data?.userId));
  }
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          href="/listing-other-goods-detail"
          uniqueID={`${data?.prdInfoId}`}
          ratioClass={ratioClass}
          galleryImgs={data?.fileList}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Link to="/listing-other-goods-detail">
        <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
          <div className="space-y-2">
            {/* <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {listingCategory.name} · {bedrooms} beds
            </span> */}
            <div className="flex items-center space-x-2">
              {/* {isAds && <Badge name="ADS" color="green" />} */}
              <h2
                className={` font-medium capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                <span className="line-clamp-1">{data?.title}</span>
              </h2>
            </div>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex justify-between items-center">
            <Link
              to="/author"
              className="flex justify-between space-x-3 hover:shadow-lg px-2 py-2 rounded-3xl"
              onClick={setAuthorId}
            >
              <div className="flex items-center">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  <Avatar
                    imgUrl={data?.url}
                    sizeClass="h-5 w-5"
                    radius="rounded-full"
                  />
                </span>
                <span className="px-2 text-sm text-neutral-500 dark:text-neutral-400">
                  {data?.nickNm}
                </span>
              </div>
            </Link>
            <span className="text-base text-sm font-semibold">
              $ {data?.price.toLocaleString()}
              {` `}
            </span>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default OtherGoodsCard;
