import React, { FC, ReactNode, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import OtherGoodsCard from "components/OtherGoodsCard/OtherGoodsCard";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PrdType } from "data/types";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import { Tab } from "@headlessui/react";

export interface SectionGridFeaturePlacesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "",
  subHeading = "",
  headingIsCenter,
  tabs = [],
}) => {
  const tab = tabs;
  const intl = useIntl();
  const recent = intl.formatMessage({ id: "RECENT" });
  tab.push(recent);
  const others = intl.formatMessage({ id: "OTHERS" });
  const fashion = intl.formatMessage({ id: "FASHION" });
  const hobbies = intl.formatMessage({ id: "HOBBIES" });
  const appliance = intl.formatMessage({ id: "APPLIANCES" });
  const cars = intl.formatMessage({ id: "CARS" });
  const realEstate = intl.formatMessage({ id: "REAL_ESTATE" });

  let [categories] = useState([
    others,
    fashion,
    hobbies,
    appliance,
    cars,
    realEstate,
  ]);

  const [data, setData] = useState<PrdType>();
  const [totalCnt, setTotalCnt] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string>("STUF000");
  const [perPageContent, setPerPageContent] = useState(8);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Product/GetPRDInfoAllPage`, {
        pageNo: 1,
        pageSize: perPageContent,
        lngcd: window.localStorage.getItem("locale"),
        category: selectedCategory,
        // userId: userId ? userId : null,
        typeABS: "A",
        sort: "0",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        setData(response.data.data[0]);
        setTotalCnt(response.data.data[0].totalCnt);
      })
      .catch(error => console.log(error));
  }, [perPageContent, selectedCategory, totalCnt]);

  function scrollToTop() {
    window.scrollTo({
      top: 3500,
      behavior: "smooth",
    });
  }

  function loadMoreContent() {
    setShowMoreLoading(true);
    setPerPageContent(perPageContent + 8);
    setTimeout(() => {
      setShowMoreLoading(false);
    }, 1000);
  }

  function toDetailPage(id: string) {
    window.sessionStorage.setItem("other-goods-id", String(id));
  }

  function listingByCategory(el: any) {
    if (el === "Others") {
      setSelectedCategory("STUF000");
    } else if (el === "Fashion") {
      setSelectedCategory("FASH000");
    } else if (el === "Hobbies") {
      setSelectedCategory("HOBB000");
    } else if (el === "Appliances") {
      setSelectedCategory("COEL000");
    } else if (el === "Cars") {
      setSelectedCategory("CARR000");
    } else if (el === "Real Estate") {
      setSelectedCategory("REAL000");
    }
    setPerPageContent(8);
  }

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <Tab.Group>
        <div className="flex justify-between">
          <HeaderFilter
            tabActive={"New York"}
            subHeading={subHeading}
            tabs={categories}
            heading={heading}
            onClickTab={e => listingByCategory(e)}
          />
          <span className="hidden sm:block flex-shrink-0">
            <ButtonSecondary
              className="!leading-none"
              href="/listing-other-goods-map"
            >
              <span>
                <FormattedMessage id="VIEW_ALL" />
              </span>
              <i className="ml-3 las la-arrow-right text-xl"></i>
            </ButtonSecondary>
          </span>
        </div>
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
        >
          {data?.productLists.map(item => (
            <div
              key={item.prdInfoId}
              onClick={() => {
                toDetailPage(String(item?.prdInfoId));
              }}
            >
              <OtherGoodsCard data={item} />
            </div>
          ))}
        </div>
      </Tab.Group>
      {totalCnt <= perPageContent ? null : (
        <div className="flex mt-16 justify-center items-center">
          <ButtonPrimary
            onClick={loadMoreContent}
            loading={showMoreLoading ? true : false}
          >
            <FormattedMessage id="SHOW_ME_MORE" />
          </ButtonPrimary>
        </div>
      )}
      <div className="flex mt-16 justify-center items-center mt-[50px]">
        <ButtonSecondary onClick={scrollToTop}>⬆️&nbsp;Top</ButtonSecondary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
