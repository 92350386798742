import { Tab } from "@headlessui/react";
import RealEstateCardH from "components/RealEstateCardH/RealEstateCardH";
import OtherGoodsCardH from "components/OtherGoodsCardH/OtherGoodsCardH";
import CarCardH from "components/CarCardH/CarCardH";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import axios from "axios";
import { PrdList } from "data/types";

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  let [categories] = useState([
    "Others",
    "Fashion",
    "Hobbies",
    "Appliances",
    "Cars",
    "Real Estate",
  ]);
  const [product, setProduct] = useState<PrdList[]>();

  useEffect(() => {
    if (window.sessionStorage.getItem("author-id")) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/Product/GetPRDInfoUserPage`, {
          pageNo: 1,
          pageSize: 500,
          lngcd: window.localStorage.getItem("locale"),
          userId: Number(window.sessionStorage.getItem("author-id")),
          category: null,
          typeABS: "A",
          sort: "1",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then(response => {
          setProduct(response.data.data[0]["productLists"]);
        })
        .catch(error => console.log(error));
    }
  }, []);

  function toGoodsDetailPage(id: string) {
    window.sessionStorage.setItem("other-goods-id", String(id));
  }
  function toCarDetailPage(id: string) {
    window.sessionStorage.setItem("cars-id", String(id));
  }
  function toEstateDetailPage(id: string) {
    window.sessionStorage.setItem("real-estate-id", String(id));
  }

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <div>
          <Avatar imgUrl={product?.[0].url} sizeClass="w-28 h-28" />
        </div>

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{""}</h2>
        </div>

        {/* ---- */}
        <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
        />

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <span className="text-neutral-6000 dark:text-neutral-300">
              {`${product?.[0].lastNm} ${product?.[0].firstNm}`}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-neutral-6000 dark:text-neutral-300">
              {product?.[0].email}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <i className="las la-map-marked text-lg">
              <span className="text-sm">{` ${product?.[0].areaName4}, ${product?.[0].areaName1}`}</span>
            </i>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap w-[760px]">
        <div>
          <h2 className="text-2xl font-semibold">
            {`${product?.[0].nickNm}'s`} listings
          </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map(item => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                  {product
                    ?.filter(item => item.goodsTypeCode === "STUF000")
                    .map(item => (
                      <div
                        key={item.prdInfoId}
                        onClick={() => {
                          toCarDetailPage(String(item.prdInfoId));
                        }}
                      >
                        <OtherGoodsCardH data={item} />
                      </div>
                    ))}
                </div>
                <div className="flex mt-11 justify-center items-center"></div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="">
                  <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                    {product
                      ?.filter(item => item.goodsTypeCode === "FASH000")
                      .map(item => (
                        <div
                          key={item.prdInfoId}
                          onClick={() => {
                            toEstateDetailPage(String(item.prdInfoId));
                          }}
                        >
                          <OtherGoodsCardH data={item} />
                        </div>
                      ))}
                  </div>
                  <div className="flex mt-11 justify-center items-center"></div>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                  {product
                    ?.filter(item => item.goodsTypeCode === "HOBB000")
                    .map(item => (
                      <div
                        key={item.prdInfoId}
                        onClick={() => {
                          toCarDetailPage(String(item.prdInfoId));
                        }}
                      >
                        <OtherGoodsCardH data={item} />
                      </div>
                    ))}
                </div>
                <div className="flex mt-11 justify-center items-center"></div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                  {product
                    ?.filter(item => item.goodsTypeCode === "COEL000")
                    .map(item => (
                      <div
                        key={item.prdInfoId}
                        onClick={() => {
                          toGoodsDetailPage(String(item.prdInfoId));
                        }}
                      >
                        <OtherGoodsCardH data={item} />
                      </div>
                    ))}
                </div>
                <div className="flex mt-11 justify-center items-center"></div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                  {product
                    ?.filter(item => item.goodsTypeCode === "CARR000")
                    .map(item => (
                      <div
                        key={item.prdInfoId}
                        onClick={() => {
                          toGoodsDetailPage(String(item.prdInfoId));
                        }}
                      >
                        <CarCardH data={item} />
                      </div>
                    ))}
                </div>
                <div className="flex mt-11 justify-center items-center"></div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                  {product
                    ?.filter(item => item.goodsTypeCode === "REAL000")
                    .map(item => (
                      <div
                        key={item.prdInfoId}
                        onClick={() => {
                          toGoodsDetailPage(String(item.prdInfoId));
                        }}
                      >
                        <RealEstateCardH data={item} />
                      </div>
                    ))}
                </div>
                <div className="flex mt-11 justify-center items-center"></div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  // const renderSection2 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* comment */}
  //       <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
  //         <CommentListing hasListingTitle className="pb-8" />
  //         <CommentListing hasListingTitle className="py-8" />
  //         <CommentListing hasListingTitle className="py-8" />
  //         <CommentListing hasListingTitle className="py-8" />
  //         <div className="pt-8">
  //           <ButtonSecondary>View more 20 reviews</ButtonSecondary>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {/* {renderSection2()} */}
        </div>
      </main>
    </div>
  );
};

export default AuthorPage;
