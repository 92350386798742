import React, { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Logo from "shared/Logo/Logo";
import MainPageImg1 from "images/mainPageThumbnail/mainPageImg1.png";
import MainPageImg2 from "images/mainPageThumbnail/mainPageImg2.png";
import MainPageImg3 from "images/mainPageThumbnail/mainPageImg3.png";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  // const [locale, setLocale] = useState(localStorage.getItem("locale") ?? "ko");
  const [renderingImg, setRenderingImg] = useState("");

  useEffect(() => {
    const mainImgs = [MainPageImg1, MainPageImg2, MainPageImg3];
    let randomImgRendering = Math.floor(Math.random() * 3);
    setRenderingImg(mainImgs[randomImgRendering]);
  }, []);

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 lg:pb-50 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%]">
            {/* <FormattedMessage id="HERO_SERVICE_TITLE"></FormattedMessage> */}
            {/*Logo Size: Upper Banner Logo: 24, Hero Page Logo:40*/}
            <Logo size={40} />
          </h2>
          <div className="pt-[20px]">
            <span
              id="hero_service_introduce_sentence"
              className="text-base md:text-lg text-neutral-500 dark:text-neutral-400"
            >
              <FormattedMessage id="HERO_SERVICE_INTRODUCE_SENTENCE"></FormattedMessage>
            </span>
          </div>
          {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
        </div>
        <div className="flex-grow">
          <img
            className="w-full rounded-[50px]"
            src={renderingImg}
            alt="hero"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
