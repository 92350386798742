import { Transition } from "@headlessui/react";
import { PrdList } from "data/types";
import React, { FC, Fragment } from "react";
import { useState } from "react";
import UserCard from "components/UserCard/UserCard";
import Avatar from "shared/Avatar/Avatar";

export interface AnyReactComponentProps {
  className?: string;
  data?: PrdList;
  isSelected?: boolean;
  lat?: number;
  lng?: number;
}

const AnyReactComponent: FC<AnyReactComponentProps> = ({
  className = "",
  isSelected,
  data,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function setAuthorId() {
    window.sessionStorage.setItem("author-id", String(data?.userId));
  }

  return (
    <div
      className={`nc-AnyReactComponent relative  ${className}`}
      data-nc-id="AnyReactComponent"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={setAuthorId}
    >
      <span
        className={`flex rounded-full bg-white dark:bg-neutral-900 text-sm font-semibold items-center justify-center min-w-max shadow-lg hover:bg-neutral-900 hover:text-white dark:hover:bg-white dark:hover:text-neutral-900 transition-colors ${
          isSelected
            ? "bg-neutral-900 text-white dark:bg-white dark:text-neutral-900"
            : ""
        }`}
      >
        <Avatar imgUrl={data?.url} sizeClass="h-[60px] w-[60px]" />
      </span>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
          <UserCard data={data} />
        </div>
      </Transition>
    </div>
  );
};

export default AnyReactComponent;
