import React, { FC, useEffect, useState } from "react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { PrdList } from "data/types";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useIntl } from "react-intl";
import SectionSliderCategory from "components/SectionSlideProduct/SectionSliderCategory";

export interface ListingCarDetailPageProps {
  className?: string;
}
const ListingCarDetailPage: FC<ListingCarDetailPageProps> = ({
  className = "",
}) => {
  const intl = useIntl();
  const categoryHeading = intl.formatMessage({ id: "CATEGORY_HEADING" });
  const categorySubHeading = intl.formatMessage({ id: "SUB_CATEGORY_HEADING" });

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [carData, setCarData] = useState<PrdList>();
  const history = useHistory();

  useEffect(() => {
    const userId = window.sessionStorage.getItem("user-id");
    const carId = window.sessionStorage.getItem("cars-id");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Product/GetPRDInfo`, {
        prdInfoId: Number(carId),
        lngcd: window.localStorage.getItem("locale"),
        userId: userId,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        setCarData(response.data.data);
      })
      .catch(error => console.log(error));

    const listenBackEvent = () => {
      window.scrollTo({
        top: 800,
        behavior: "smooth",
      });
    };

    const unlistenHistoryEvent = history.listen(location => {
      if (history.action === "POP") {
        listenBackEvent();
      }
    });

    return unlistenHistoryEvent;
  }, [history]);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  function toAuthorPage() {
    window.sessionStorage.setItem("author-id", String(carData?.userId));
  }

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          {/* <Badge color="pink" name="BMW car" /> */}
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {carData?.title}
        </h2>

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating reviewCount={carData?.reviewCount} point={carData?.reviewStart}/>
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {carData?.address}</span>
          </span>
        </div> */}

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Car owner{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          {/* <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
            <span className="">{carData?.seats} seats</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-dharmachakra text-2xl"></i>
            <span className=""> {carData?.gearshift}</span>
          </div> */}
          {/* <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-suitcase text-2xl"></i>
            <span className=""> 2 bags</span>
          </div> */}
        </div>
      </div>
    );
  };

  //
  // const renderSectionTienIch = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       <div>
  //         <h2 className="text-2xl font-semibold">
  //           Vehicle parameters & utilities{" "}
  //         </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Questions are at the heart of making things great.
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* 6 */}
  //       <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
  //         {/* TIEN ICH 1 */}
  //         {Amenities_demos.map((item, index) => (
  //           <div key={index} className="flex items-center space-x-4 ">
  //             <div className="w-10 flex-shrink-0">
  //               <img src={item.icon} alt="" />
  //             </div>
  //             <span>{item.name}</span>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Car descriptions</h2>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {carData?.content.split("\n").map((line: any, index) => {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })}
          </span>
        </div>
      </div>
    );
  };

  // const renderSection3 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       <div>
  //         <h2 className="text-2xl font-semibold">Include </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Included in the price
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* 6 */}
  //       <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
  //         {includes_demo
  //           .filter((_, i) => i < 12)
  //           .map((item) => (
  //             <div key={item.name} className="flex items-center space-x-3">
  //               <i className="las la-check-circle text-2xl"></i>
  //               <span>{item.name}</span>
  //             </div>
  //           ))}
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSectionCheckIndate = () => {
  //   return (
  //     <div className="listingSection__wrap overflow-hidden">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Availability</h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}
  //       <div className="listingSection__wrap__DayPickerRangeController flow-root">
  //         <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
  //           <DayPickerRangeController
  //             startDate={dateRangeValue.startDate}
  //             endDate={dateRangeValue.endDate}
  //             onDatesChange={(date) => setDateRangeValue(date)}
  //             focusedInput={focusedInputSectionCheckDate}
  //             onFocusChange={(focusedInput) =>
  //               setFocusedInputSectionCheckDate(focusedInput || "startDate")
  //             }
  //             initialVisibleMonth={null}
  //             numberOfMonths={windowSize.width < 1280 ? 1 : 2}
  //             daySize={getDaySize()}
  //             hideKeyboardShortcutsPanel
  //           />
  //         </div>
  //       </div>

  // {/*  */}
  //       <div className="flex space-x-8">
  //         <div className="w-1/2 space-y-2">
  //           <label className="font-medium" htmlFor="startTime">
  //             Pick up time:
  //           </label>
  //           <Input
  //             defaultValue={timeRangeValue.startTime}
  //             rounded="rounded-xl"
  //             id="startTime"
  //             type="time"
  //           />
  //         </div>
  //         <div className="w-1/2 space-y-2">
  //           <label className="font-medium" htmlFor="endTime">
  //             Drop off time:
  //           </label>
  //           <Input
  //             defaultValue={timeRangeValue.endTime}
  //             rounded="rounded-xl"
  //             id="endTime"
  //             type="time"
  //             onChange={(e) => console.log(e)}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Car Owner</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            imgUrl={carData?.url}
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a
              className="block text-xl font-medium"
              href="/author"
              onClick={toAuthorPage}
            >
              {carData?.nickNm}
            </a>
            <span className="text-sm text-neutral-500">{`${carData?.areaName4} ${carData?.areaName1}`}</span>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400"></div>
          </div>
        </div>
        {carData?.geom_Lat ? (
          <div>
            {/* HEADING */}
            <div>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {/* {carData?.address} */}
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* MAP */}
            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
              <div className="rounded-xl overflow-hidden">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyA4IN3sVjTcoW0b9OXd6cq_IbKSd6PUurM",
                  }}
                  defaultZoom={15}
                  yesIWantToUseGoogleMapApiInternals
                  defaultCenter={{
                    lat: carData.geom_Lat,
                    lng: carData.geom_Lon,
                  }}
                >
                  <LocationMarker
                    lat={carData.geom_Lat}
                    lng={carData.geom_Lon}
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        ) : null}

        {/* desc */}
        {/* <span className="block text-neutral-6000 dark:text-neutral-300">
          {carData.author.desc}
        </span> */}

        {/* info */}
        {/* <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div> */}

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="author" onClick={toAuthorPage}>
            See host profile
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  // const renderSection6 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Reviews ({carData?.reviewCount} reviews)</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* Content */}
  //       <div className="space-y-5">
  //         <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
  //         <div className="relative">
  //           <Input
  //             fontClass=""
  //             sizeClass="h-16 px-4 py-3"
  //             rounded="rounded-3xl"
  //             placeholder="Share your thoughts ..."
  //           />
  //           <ButtonCircle
  //             className="absolute right-2 top-1/2 transform -translate-y-1/2"
  //             size=" w-12 h-12 "
  //           >
  //             <ArrowRightIcon className="w-5 h-5" />
  //           </ButtonCircle>
  //         </div>
  //       </div>

  //       {/* comment */}
  //       <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <div className="pt-8">
  //           <ButtonSecondary>View more 20 reviews</ButtonSecondary>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection7 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Things to know</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Cancellation policy</h4>
  //         <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
  //           Lock in this fantastic price today, cancel free of charge anytime.
  //           Reserve now and pay at pick-up.
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Special Note</h4>
  //         <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
  //           We asked ourselves, “How can we make the dash not only look better,
  //           but also give the driver a better look outside?” The unexpected
  //           answer is having no hood above the available 10.25-inch digital
  //           instrument cluster...
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSidebarPrice = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        {/* <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $19
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /day
            </span>
          </span>
          <StartRating />
        </div> */}

        {/* FORM */}
        {/* <form className="flex border  border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <RentalCarDatesRangeInput
            defaultDateValue={dateRangeValue}
            defaultTimeValue={timeRangeValue}
            numberOfMonths={1}
            fieldClassName="p-5"
            wrapFieldClassName="flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700"
            className="RentalCarDetailPageDatesRangeInput"
            onChange={(data) => {
              setDateRangeValue(data.stateDate);
              setTimeRangeValue(data.stateTimeRage);
            }}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
        </form> */}

        {/* SUM */}
        <div className="flex flex-col space-y-4 ">
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div> */}

          {/* <div className="border-b border-neutral-200 dark:border-neutral-700"></div> */}
          <div className="flex justify-between font-semibold">
            <span>$ {carData?.price.toLocaleString()}</span>
            <span className="flex items-center">
              {carData?.like}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill={"currentColor"}
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary
          onClick={() => {
            alert("service in preparation");
          }}
        >
          Reserve
        </ButtonPrimary>
      </div>
    );
  };

  // const renderSidebarDetail = () => {
  //   return (
  //     <div className="listingSection__wrap lg:shadow-xl">
  //       <span className="text-2xl font-semibold block">
  //         Pick up and drop off
  //       </span>
  //       <div className="mt-8 flex">
  //         <div className="flex-shrink-0 flex flex-col items-center py-2">
  //           <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
  //           <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
  //           <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
  //         </div>
  //         <div className="ml-4 space-y-14 text-sm">
  //           <div className="flex flex-col space-y-2">
  //             <span className=" text-neutral-500 dark:text-neutral-400">
  //               Monday, August 12 · 10:00
  //             </span>
  //             <span className=" font-semibold">
  //               Saint Petersburg City Center
  //             </span>
  //           </div>
  //           <div className="flex flex-col space-y-2">
  //             <span className=" text-neutral-500 dark:text-neutral-400">
  //               Monday, August 16 · 10:00
  //             </span>
  //             <span className=" font-semibold">
  //               Saint Petersburg City Center
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div
      className={`nc-ListingCarDetailPage  ${className}`}
      data-nc-id="ListingCarDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 h-[520px] row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={carData?.fileList[0].url}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {carData?.fileList
              .filter((_, i) => i > 0)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    item.filePathId >= 0 ? "hidden sm:block" : ""
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item.url}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(item.filePathId + 1)}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={carData?.fileList}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          {/* <div className="block lg:hidden">{renderSidebarDetail()}</div> */}
          {/* {renderSectionTienIch()} */}
          {renderSection2()}
          {/* {renderSection3()} */}
          {/* {renderSectionCheckIndate()} */}
          {renderSection5()}
          {/* {renderSection6()} */}
          {/* {renderSection7()} */}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          {/* {renderSidebarDetail()} */}
          <div className="mt-10 sticky top-24">{renderSidebarPrice()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {/* <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
        <div className="container flex items-center justify-between">
          <span className="text-2xl font-semibold">
            $311
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /day
            </span>
          </span>

          <ButtonPrimary href="##">Reserve</ButtonPrimary>
        </div>
      </div> */}

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderCategory
            heading={categoryHeading}
            subHeading={categorySubHeading}
            categoryCardType="card3"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="PageHome_s1"
          />
        </div>

        {/* SECTION */}
        {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
      </div>
    </div>
  );
};

export default ListingCarDetailPage;
