/** @format */

import React from "react";
import { Link } from "react-router-dom";
import logoLightImg from "images/logos/dark/logo-dark.svg";
import logoImg from "images/logos/normal/logo-light.svg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  size?: number;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
  size,
}) => {
  return (
    <Link
      to="/"
      className={`mt-30 ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      {/* Disabled Direct Calling of SVG file */}
      {/* <LogoSvgLight />
      <LogoSvg /> */}
      {img ? (
        <img
          //Adjust size of Logo.
          // className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          className={`${size ? "w-" + size : "w-24"} block dark:hidden ${
            imgLight ? "dark:hidden" : ""
          }`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className={`hidden ${size ? "w-" + size : "w-24"} block dark:block`}
          src={imgLight}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
