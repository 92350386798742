import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderPopularProduct from "components/SectionSlideProduct/SectionSliderPopularProduct";
import SectionSliderCategory from "components/SectionSlideProduct/SectionSliderCategory";
import { useEffect, useState } from "react";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { PrdType } from "data/types";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import axios from "axios";

function PageHome() {
  // const userId = getCookie("user-id");
  const [popularData, setPopularData] = useState<PrdType>();

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Product/GetPRDInfoAllPage`, {
        pageNo: 1,
        pageSize: 500,
        lngcd: window.localStorage.getItem("locale"),
        category: null,
        // userId: userId ? userId : null,
        typeABS: "A",
        sort: "0",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        setPopularData(response.data.data[0]);
      })
      .catch(error => console.log(error));

    const storage = window.sessionStorage;
    storage.removeItem("goods-current-content");
    storage.removeItem("car-current-content");
    storage.removeItem("estate-current-content");
  }, []);

  const intl = useIntl();
  const categoryHeading = intl.formatMessage({ id: "CATEGORY_HEADING" });
  const categorySubHeading = intl.formatMessage({ id: "SUB_CATEGORY_HEADING" });
  const popular = intl.formatMessage({ id: "POPULAR" });
  const popularSubHeading = intl.formatMessage({ id: "POPULAR_SUB_HEADING" });
  const otherGoodsHeading = intl.formatMessage({ id: "OTHER_GOODS_HEADING" });
  const otherGoosSubHeading = intl.formatMessage({
    id: "OTHER_GOODS_SUB_HEADING",
  });

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>NE:ME || Best Online e-Commerce</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10" />

        {/* SECTION CATEGORY */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderCategory
            heading={categoryHeading}
            subHeading={categorySubHeading}
            categoryCardType="card3"
            itemPerRow={4}
            sliderStyle="style2"
            uniqueClassName="PageHome_s1"
          />
        </div>

        {/* SECTION POPULAR LIST */}
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderPopularProduct
            data={popularData?.productLists}
            heading={popular}
            subHeading={popularSubHeading}
            categoryCardType="card4"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>

        {/* SECTION2 INTRODUCE */}
        <SectionOurFeatures />

        {/* SECTION OTHER GOODS */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces
            heading={otherGoodsHeading}
            subHeading={otherGoosSubHeading}
          />
        </div>

        {/* SECTION */}
        {/* <SectionHowItWork /> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div> */}
      </div>
    </div>
  );
}

export default PageHome;
