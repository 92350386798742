import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Badge from "shared/Badge/Badge";
import { removeCookie } from "utils/cookies";

export interface PageFindPasswordProps {
  className?: string;
  onChange?: () => void;
  onClick?: () => void;
}
axios.defaults.withCredentials = false;

const validEmail = (e: string) => {
  const resurt =
    /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  return resurt.test(String(e).toLowerCase());
};

const PageFindPassword: FC<PageFindPasswordProps> = ({ className = "" }) => {
  const [confirmEmailCode, setConfirmEmailCode] = useState("");
  const [user, setUser] = useState({
    email: "",
    emailCode: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { email, emailCode, newPassword, confirmPassword } = user;

  useEffect(() => {}, []);

  function userInputEmail(e: any) {
    setUser(el => ({
      ...el,
      email: e.target.value,
    }));
    if (!validEmail(e.target.value)) {
      setUser(el => ({
        ...el,
        emailCode: "",
        newPassword: "",
        confirmPassword: "",
      }));
      setConfirmEmailCode("");
    }
  }

  function userInputCode(e: any) {
    setUser(el => ({
      ...el,
      emailCode: e.target.value,
    }));
  }

  function userInputPassword(e: any) {
    setUser(el => ({
      ...el,
      newPassword: e.target.value,
    }));
  }

  function userInputConfirmPassword(e: any) {
    setUser(el => ({
      ...el,
      confirmPassword: e.target.value,
    }));
  }

  function sendCode() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Users/CheckPwEmail`, {
        lngcd: "en",
        email: email,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.status === 200) {
          setConfirmEmailCode(response.data.data.code);
          alert("Please check the code sent to your email");
        } else if (response.data.status === 500) {
          alert("Service Error");
          return;
        } else {
          alert("Invaild Email");
          return;
        }
      })
      .catch(error => console.log(error));
  }

  function changePassword() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Users/SetUserPwUp`, {
        email: String(email),
        password: String(confirmPassword),
        code: String(emailCode),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.status === 200) {
          alert("Succeed change password, please re login");
          window.sessionStorage.clear();
          removeCookie("access-token");
          removeCookie("refresh-token");
          window.location.href = "/login";
        } else if (response.data.status === 500) {
          alert("Service Error");
          return;
        } else {
          alert("Invaild Email");
          return;
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forgot Password?
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* OR */}
          <div className="relative text-center">
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block mt-10">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={e => userInputEmail(e)}
              />
            </label>
            <ButtonPrimary
              type="button"
              onClick={sendCode}
              disabled={validEmail(email) ? false : true}
            >
              Send Code
            </ButtonPrimary>
            <label className="block">
              <div className="flex">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Confirm Code
                </span>
                {emailCode.length > 0 ? (
                  confirmEmailCode === emailCode ? (
                    <Badge color="green" name="Available" />
                  ) : (
                    <Badge color="red" name="Unavailable" />
                  )
                ) : (
                  <span />
                )}
              </div>
              <Input
                type="text"
                value={validEmail(email) ? emailCode : ""}
                className={
                  confirmEmailCode.length > 0 ? "mt-1" : "mt-1 bg-[#e1e1e1]"
                }
                onChange={e => userInputCode(e)}
                disabled={confirmEmailCode.length > 0 ? false : true}
              />
            </label>
            <form className="grid grid-cols-1 gap-6" action="#" method="post" />
            {validEmail(email) ? (
              <>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200 mt-3">
                    Password
                  </span>
                  <Input
                    type="password"
                    className={
                      emailCode.length > 0
                        ? emailCode === confirmEmailCode
                          ? "mt-1"
                          : "mt-1 bg-[#e1e1e1]"
                        : "mt-1 bg-[#e1e1e1]"
                    }
                    placeholder={
                      emailCode.length > 0
                        ? emailCode === confirmEmailCode
                          ? "Password must be at least 8 characters"
                          : ""
                        : ""
                    }
                    onChange={e => userInputPassword(e)}
                    disabled={
                      emailCode.length > 0
                        ? emailCode === confirmEmailCode
                          ? false
                          : true
                        : true
                    }
                  />
                  <div className="flex">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200 mt-3">
                      Confirm Password &nbsp;
                      {newPassword.length > 0 ? (
                        newPassword === confirmPassword &&
                        newPassword.length > 7 &&
                        confirmPassword.length > 7 ? (
                          <Badge color="green" name="Available" />
                        ) : (
                          <Badge color="red" name="Unavailable" />
                        )
                      ) : (
                        <span />
                      )}
                    </span>
                  </div>
                  <Input
                    type="password"
                    className={
                      emailCode.length > 0
                        ? emailCode === confirmEmailCode
                          ? "mt-1"
                          : "mt-1 bg-[#e1e1e1]"
                        : "mt-1 bg-[#e1e1e1]"
                    }
                    onChange={e => userInputConfirmPassword(e)}
                    disabled={
                      emailCode.length > 0
                        ? emailCode === confirmEmailCode
                          ? false
                          : true
                        : true
                    }
                  />
                </label>
                <ButtonPrimary
                  type="button"
                  disabled={
                    emailCode.length > 0 &&
                    emailCode === confirmEmailCode &&
                    newPassword.length > 7 &&
                    newPassword === confirmPassword
                      ? false
                      : true
                  }
                  onClick={changePassword}
                >
                  Continue
                </ButtonPrimary>
              </>
            ) : (
              ""
            )}
          </form>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" onClick={() => window.sessionStorage.clear()}>
              <u>Create an account</u>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageFindPassword;
