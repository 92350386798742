import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { getCookie, removeCookie } from "utils/cookies";
// import SwitchLocale from "shared/SwitchLocale/SwitchLocale";
import { FormattedMessage } from "react-intl";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const location = window.location.href.includes("/login");
  const [locale] = useState(localStorage.getItem("locale") ?? "en");

  useEffect(() => {
    localStorage.setItem("locale", locale);
  }, [locale]);

  function signOut() {
    window.sessionStorage.clear();
    removeCookie("access-token");
    removeCookie("refresh-token");
    removeCookie("user-id");
    removeCookie("uid");
  }

  function signIn() {}

  // const switchLocation = (e: React.MouseEvent, locale: string) => {
  //   setLocale(locale);
  //   localStorage.setItem("locale", locale);
  // };

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo size={24} />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            {/* <SwitchLocale
              switchLocation={locale => setLocale(locale)}
              current_locale={locale}
            /> */}
            <SwitchDarkMode />
            <SearchDropdown />
            <div className="px-1" />
            <ButtonPrimary
              className="w-[120px]"
              onClick={getCookie("access-token") ? signOut : signIn}
              href={
                window.location.href.includes("/login") ? "/signup" : "/login"
              }
            >
              {!location ? (
                getCookie("access-token") ? (
                  <FormattedMessage id="SIGN_OUT"></FormattedMessage>
                ) : (
                  <FormattedMessage id="SIGN_IN"></FormattedMessage>
                )
              ) : (
                <FormattedMessage id="SIGN_UP"></FormattedMessage>
              )}
            </ButtonPrimary>
          </div>
          <div className="flex items-center xl:hidden">
            {/* <SwitchLocale /> */}
            <SwitchDarkMode />
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
