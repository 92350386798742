import React, { FC, useEffect, useState } from "react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { PrdList } from "data/types";
import axios from "axios";
import { useIntl } from "react-intl";
import SectionSliderCategory from "components/SectionSlideProduct/SectionSliderCategory";

export interface ListingOtherGoodsDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  startRating?: number;
}

const ListingOtherGoodsDetailPage: FC<ListingOtherGoodsDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const intl = useIntl();
  const categoryHeading = intl.formatMessage({ id: "CATEGORY_HEADING" });
  const categorySubHeading = intl.formatMessage({ id: "SUB_CATEGORY_HEADING" });

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [prdData, setPrdData] = useState<PrdList>();

  useEffect(() => {
    const userId = window.sessionStorage.getItem("user-id");
    const prdId = window.sessionStorage.getItem("other-goods-id");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Product/GetPRDInfo`, {
        prdInfoId: Number(prdId),
        lngcd: window.localStorage.getItem("locale"),
        userId: userId,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        setPrdData(response.data.data);
        console.log(response);
      })
      .catch(error => console.log(error));
  }, []);

  function setAuthorId() {
    window.sessionStorage.setItem("author-id", String(prdData?.userId));
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        <div className="flex justify-between items-center">
          <LikeSaveBtns />
        </div>
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {prdData?.title}
        </h2>
      </div>
    );
  };
  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {prdData?.content.split("\n").map((line: any, index) => {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })}
          </span>
        </div>
      </div>
    );
  };

  // const renderSection3 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       <div>
  //         <h2 className="text-2xl font-semibold">Amenities </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           About the property's amenities and services
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* 6 */}
  //       {/* <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
  //         {Amenities_demos.filter((_, i) => i < 12).map((item) => (
  //           <div key={item.name} className="flex items-center space-x-3">
  //             <i className={`text-3xl las ${item.icon}`}></i>
  //             <span className=" ">{item.name}</span>
  //           </div>
  //         ))}
  //       </div> */}

  //       {/* ----- */}
  //       <div className="w-14 border-b border-neutral-200"></div>
  //       <div>
  //         <ButtonSecondary onClick={openModalAmenities}>
  //           View more 20 amenities
  //         </ButtonSecondary>
  //       </div>
  //       {renderMotalAmenities()}
  //     </div>
  //   );
  // };

  // const renderMotalAmenities = () => {
  //   return (
  //     <Transition appear show={isOpenModalAmenities} as={Fragment}>
  //       <Dialog
  //         as="div"
  //         className="fixed inset-0 z-50 overflow-y-auto"
  //         onClose={closeModalAmenities}
  //       >
  //         <div className="min-h-screen px-4 text-center">
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0"
  //             enterTo="opacity-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100"
  //             leaveTo="opacity-0"
  //           >
  //             <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
  //           </Transition.Child>

  //           {/* This element is to trick the browser into centering the modal contents. */}
  //           <span
  //             className="inline-block h-screen align-middle"
  //             aria-hidden="true"
  //           >
  //             &#8203;
  //           </span>
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0 scale-95"
  //             enterTo="opacity-100 scale-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100 scale-100"
  //             leaveTo="opacity-0 scale-95"
  //           >
  //             <div className="inline-block py-8 h-screen w-full">
  //               <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
  //                 <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
  //                   <h3
  //                     className="text-lg font-medium leading-6 text-gray-900"
  //                     id="headlessui-dialog-title-70"
  //                   >
  //                     Amenities
  //                   </h3>
  //                   <span className="absolute left-3 top-3">
  //                     <ButtonClose onClick={closeModalAmenities} />
  //                   </span>
  //                 </div>
  //                 {/* <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
  //                   {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
  //                     <div
  //                       key={item.name}
  //                       className="flex items-center py-6 space-x-8"
  //                     >
  //                       <i
  //                         className={`text-4xl text-neutral-6000 las ${item.icon}`}
  //                       ></i>
  //                       <span>{item.name}</span>
  //                     </div>
  //                   ))}
  //                 </div> */}
  //               </div>
  //             </div>
  //           </Transition.Child>
  //         </div>
  //       </Dialog>
  //     </Transition>
  //   );
  // };

  // const renderSection4 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Room Rates </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}
  //       <div className="flow-root">
  //         <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Friday - Sunday</span>
  //             <span>$219</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Rent by month</span>
  //             <span>-8.34 %</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Minimum number of nights</span>
  //             <span>1 night</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Max number of nights</span>
  //             <span>90 nights</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSectionCheckIndate = () => {
  //   return (
  //     <div className="listingSection__wrap overflow-hidden">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Availability</h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}

  //       <div className="listingSection__wrap__DayPickerRangeController flow-root">
  //         <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
  //           <DayPickerRangeController
  //             startDate={selectedDate.startDate}
  //             endDate={selectedDate.endDate}
  //             onDatesChange={(date) => setSelectedDate(date)}
  //             focusedInput={focusedInputSectionCheckDate}
  //             onFocusChange={(focusedInput) =>
  //               setFocusedInputSectionCheckDate(focusedInput || "startDate")
  //             }
  //             initialVisibleMonth={null}
  //             numberOfMonths={windowSize.width < 1280 ? 1 : 2}
  //             daySize={getDaySize()}
  //             hideKeyboardShortcutsPanel={false}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            imgUrl={prdData?.url}
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a
              className="block text-xl font-medium"
              href="/author"
              onClick={setAuthorId}
            >
              {prdData?.nickNm}
            </a>
            <span className="text-sm text-neutral-500">{`${prdData?.areaName4} ${prdData?.areaName1}`}</span>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400"></div>
          </div>
        </div>
        {prdData?.geom_Lat ? (
          <div>
            {/* HEADING */}
            <div>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {/* {prdData} */}
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* MAP */}
            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
              <div className="rounded-xl overflow-hidden">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyA4IN3sVjTcoW0b9OXd6cq_IbKSd6PUurM",
                  }}
                  defaultZoom={15}
                  // yesIWantToUseGoogleMapApiInternals
                  defaultCenter={{
                    lat: Number(prdData.geom_Lat),
                    lng: Number(prdData.geom_Lon),
                  }}
                >
                  <LocationMarker
                    lat={prdData.geom_Lat}
                    lng={prdData.geom_Lon}
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        ) : null}
        {/* desc */}
        {/* <span className="block text-neutral-6000 dark:text-neutral-300">
          {prdData.author.desc}
        </span> */}

        {/* info */}
        {/* <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div> */}

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author" onClick={setAuthorId}>
            See host profile
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  // const renderSection6 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       {/* <h2 className="text-2xl font-semibold">Reviews ({prdData.reviewCount} reviews)</h2> */}
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* Content */}
  //       <div className="space-y-5">
  //         <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
  //         <div className="relative">
  //           <Input
  //             fontClass=""
  //             sizeClass="h-16 px-4 py-3"
  //             rounded="rounded-3xl"
  //             placeholder="Share your thoughts ..."
  //           />
  //           <ButtonCircle
  //             className="absolute right-2 top-1/2 transform -translate-y-1/2"
  //             size=" w-12 h-12 "
  //           >
  //             <ArrowRightIcon className="w-5 h-5" />
  //           </ButtonCircle>
  //         </div>
  //       </div>

  //       {/* comment */}
  //       <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <div className="pt-8">
  //           <ButtonSecondary>View more 20 reviews</ButtonSecondary>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection7 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Things to know</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Cancellation policy</h4>
  //         <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
  //           Refund 50% of the booking value when customers cancel the room
  //           within 48 hours after successful booking and 14 days before the
  //           check-in time. <br />
  //           Then, cancel the room 14 days before the check-in time, get a 50%
  //           refund of the total amount paid (minus the service fee).
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Check-in time</h4>
  //         <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
  //           <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
  //             <span>Check-in</span>
  //             <span>08:00 am - 12:00 am</span>
  //           </div>
  //           <div className="flex space-x-10 justify-between p-3">
  //             <span>Check-out</span>
  //             <span>02:00 pm - 04:00 pm</span>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Special Note</h4>
  //         <div className="prose sm:prose">
  //           <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
  //             <li>
  //               Ban and I will work together to keep the landscape and
  //               environment green and clean by not littering, not using
  //               stimulants and respecting people around.
  //             </li>
  //             <li>Do not sing karaoke past 11:30</li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $ {prdData?.price.toLocaleString()}
            {/* <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span> */}
          </span>
          <span className="flex items-center">
            {prdData?.like}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill={"currentColor"}
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
          </span>
        </div>

        {/* FORM */}
        {/* <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
          />
        </form> */}

        {/* SUM */}
        {/* <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$119 x 3 night</span>
            <span>$357</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$199</span>
          </div>
        </div> */}

        {/* SUBMIT */}
        <ButtonPrimary
          onClick={() => {
            alert("service in preparation");
          }}
        >
          Reserve
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingOtherGoodsDetailPage  ${className}`}
      data-nc-id="ListingOtherGoodsDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 h-[520px] row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={prdData?.fileList[0].url}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {prdData?.fileList
              .filter((_, i) => i > 0)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    item.filePathId >= 0 ? "hidden sm:block" : ""
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item.url}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(item.filePathId + 1)}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={prdData?.fileList}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingOtherGoodsDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {/* {renderSection3()} */}
          {/* {renderSection4()} */}
          {/* {renderSectionCheckIndate()} */}
          {renderSection5()}
          {/* {renderSection6()} */}
          {/* {renderSection7()} */}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {/* {!isPreviewMode && (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              $311
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /night
              </span>
            </span>

            <ButtonPrimary href="##">Reserve</ButtonPrimary>
          </div>
        </div>
      )} */}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderCategory
              heading={categoryHeading}
              subHeading={categorySubHeading}
              categoryCardType="card3"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName="PageHome_s1"
            />
          </div>
          {/* <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName={"ListingOtherGoodsDetailPage"}
            />
          </div> */}

          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
    </div>
  );
};

export default ListingOtherGoodsDetailPage;
