import axios from "axios";
import Label from "components/Label/Label";
import { UserType } from "data/types";
import React, { useEffect, useState } from "react";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { getCookie, removeCookie } from "utils/cookies";
import CommonLayout from "./CommonLayout";

const AccountPass = () => {
  const accessToken: string = getCookie("access-token");
  const refreshToken: string = getCookie("refresh-token");
  const userId = Number(getCookie("user-id"));
  const [password, setPassword] = useState({
    currentPw: "",
    newPw: "",
    confirmPw: "",
  });
  const [user, setUser] = useState<UserType>();
  const [typeCd, setTypeCd] = useState({
    typeCd: "",
  });
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/Users/GetUserInfo`,
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: accessToken,
            subAuthorization: refreshToken,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then(response => {
        setUser(response.data.data);
      })
      .catch(error => console.log(error));
    if (user?.email.includes("google.com")) {
      setTypeCd({
        typeCd: "1",
      });
    } else if (user?.email.includes("apple.com")) {
      setTypeCd({
        typeCd: "2",
      });
    } else {
      setTypeCd({
        typeCd: "0",
      });
    }
  }, []);

  function currentPwInput(e: any) {
    setPassword(el => ({
      ...el,
      currentPw: e.target.value,
    }));
  }
  function newPwInput(e: any) {
    setPassword(el => ({
      ...el,
      newPw: e.target.value,
    }));
  }
  function confirmPwInput(e: any) {
    setPassword(el => ({
      ...el,
      confirmPw: e.target.value,
    }));
  }

  function updatePw() {
    if (password.currentPw === password.confirmPw) {
      alert("Password is already in use");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Users/login`, {
        lngCd: window.localStorage.getItem("locale"),
        snsTypeCd: "0",
        email: user?.email,
        password: password.currentPw,
        snsUid: "",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.status === 200) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/Users/SetUserInfoUp`,
              {
                userId: Number(getCookie("user-id")),
                typeCd: typeCd.typeCd,
                lngCd: window.localStorage.getItem("locale"),
                firstNm: user?.firstNm,
                lastNm: user?.lastNm,
                nickNm: user?.nickNm,
                email: user?.email,
                terms: true,
                privacy: true,
                password: password.confirmPw,
              },
              {
                headers: {
                  Authorization: String(accessToken),
                  subAuthorization: String(refreshToken),
                  "Content-type": "application/json; charset=UTF-8",
                },
              }
            )
            .then(response => {
              if (response.data.status === 200) {
                window.sessionStorage.clear();
                removeCookie("access-token");
                removeCookie("refresh-token");
                removeCookie("user-id");
                alert("Password change succefully, please login again");
                window.location.replace("/login");
              } else {
                alert("Service Error");
                return;
              }
            })
            .catch(error => console.log(error));
        } else if (response.data.status >= 400 && response.data.status < 500) {
          alert("Please check current password");
          return;
        } else {
          alert("Service Error");
          return;
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current password</Label>
              <Input
                type="password"
                className="mt-1.5"
                onChange={currentPwInput}
              />
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" className="mt-1.5" onChange={newPwInput} />
            </div>
            <div>
              <Label>Confirm password</Label>
              {password.newPw !== password.confirmPw ? (
                <Badge color="red" name="invaild" className="ml-1" />
              ) : null}
              <Input
                type="password"
                className="mt-1.5"
                onChange={confirmPwInput}
              />
            </div>
            <div className="pt-2">
              <ButtonPrimary
                onClick={updatePw}
                disabled={
                  password.newPw.length > 0
                    ? password.confirmPw === password.newPw
                      ? false
                      : true
                    : true
                }
              >
                Update password
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
