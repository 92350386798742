import { PrdList } from "data/types";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import { Link } from "react-router-dom";
export interface UserCardProps {
  data?: PrdList;
  dataLength?: number;
}

const UserCard: FC<UserCardProps> = ({ data, dataLength }) => {
  //   function setAuthorId() {
  //   }

  return (
    <Link to="/author">
      <div className="flex items-center shadow-2xl bg-white dark:bg-neutral-900 rounded-full py-1 pr-3">
        <span className="py-1 px-2">
          <Avatar imgUrl={data?.url} sizeClass="h-[100px] w-[100px]" />
        </span>
        <div className="flex flex-col">
          <span className="text-lg">{data?.nickNm}</span>
          <i className="las la-map-marked text-sm">
            <span className="text-sm text-neutral-300">{` ${data?.areaName4}, ${data?.areaName1}`}</span>
          </i>
          <span className="text-sm text-neutral-300 mt-3">go to profile</span>
        </div>
      </div>
    </Link>
  );
};

export default UserCard;
