import React, { FC } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero2ArchivePage from "components/SectionHero2ArchivePage/SectionHero2ArchivePage";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import heroRightImage from "images/hero-right-car.png";
import { useIntl } from "react-intl";
import SectionSliderCategory from "components/SectionSlideProduct/SectionSliderCategory";

export interface ListingCarMapPageProps {
  className?: string;
}

const ListingCarMapPage: FC<ListingCarMapPageProps> = ({ className = "" }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: "CARS" });
  const categoryHeading = intl.formatMessage({ id: "CATEGORY_HEADING" });
  const categorySubHeading = intl.formatMessage({ id: "SUB_CATEGORY_HEADING" });
  return (
    <div
      className={`nc-ListingCarMapPage relative ${className}`}
      data-nc-id="ListingCarMapPage"
    >
      <Helmet>
        <title>NE:ME || Best Online e-Commerce</title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container pb-24 lg:pb-32">
        <SectionHero2ArchivePage
          className="lg:mt-2"
          title={title}
          adress="USA"
          properties="0"
          rightImage={heroRightImage}
        />
      </div>

      {/* SECTION */}
      <div className="container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap />
      </div>

      <div className="container overflow-hidden rounded-[50px]">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderCategory
            heading={categoryHeading}
            subHeading={categorySubHeading}
            categoryCardType="card3"
            itemPerRow={4}
            sliderStyle="style2"
            uniqueClassName="PageHome_s1"
          />
        </div>

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-32">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default ListingCarMapPage;
