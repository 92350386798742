import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import { FormattedMessage, useIntl } from "react-intl";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  const intl = useIntl();
  const environment = intl.formatMessage({ id: "ENVIRONMENT" });
  const neighborhood = intl.formatMessage({ id: "NEIGHBORHOOD" });
  const communication = intl.formatMessage({ id: "COMMUNICATION" });
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          INTRODUCE
        </span>
        <h2 className="font-semibold text-4xl mt-5">
          <FormattedMessage id="A_WISE_OTHER_GOODS_DEAL" />
        </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge color="green" name={environment} />
            <span className="block text-xl font-semibold">
              <FormattedMessage id="ACHIEVE_CARBON_NEUTRALITY_FOR_EARTH" />
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name={neighborhood} />
            <span className="block text-xl font-semibold">
              <FormattedMessage id="BRING_YOUR_NEIGHBORHOOD_TOGETHER" />
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name={communication} />
            <span className="block text-xl font-semibold">
              <FormattedMessage id="ACTIVATION_OF_LOCAL_BUSINESS" />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
