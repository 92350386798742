import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { PrdList } from "data/types";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Avatar from "shared/Avatar/Avatar";

export interface OtherGoodsCardHProps {
  className?: string;
  data: PrdList;
}

const OtherGoodsCardH: FC<OtherGoodsCardHProps> = ({
  className = "",
  data,
}) => {
  function setAuthorId() {
    window.sessionStorage.setItem("author-id", String(data?.userId));
  }

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full sm:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={data.fileList}
          uniqueID={`${data?.prdInfoId}`}
          href="/listing-other-goods-detail"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Link
        to="/listing-other-goods-detail"
        className="flex-grow p-3 sm:p-5 flex flex-col"
      >
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2 className="text-3xl font-medium capitalize">
              <span className="line-clamp-1">{data?.title}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {window.location.href.includes("/author") ? null : (
          <i className="las la-map-marked text-lg">
            <span className="text-sm text-neutral-500">{` ${data?.areaName1}, ${data?.areaName4}`}</span>
          </i>
        )}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-10"></div>
        <div className="flex items-center justify-between items-end">
          {window.location.href.includes("/author") ? null : (
            <Link
              to="/author"
              className="flex justify-between space-x-3 hover:shadow-lg px-2 py-2 rounded-3xl"
              onClick={setAuthorId}
            >
              <div className="flex items-center">
                <span className="z-5 text-sm text-neutral-500 dark:text-neutral-400">
                  <Avatar
                    imgUrl={data?.url}
                    sizeClass="h-10 w-10"
                    radius="rounded-full"
                  />
                </span>
                <span className="px-2 text-lg text-neutral-500 dark:text-neutral-400">
                  {data?.nickNm}
                </span>
              </div>
            </Link>
          )}
          <span className="text-xl text-base text-secondary-500 ml-[10px]">
            {`$ ${data?.price.toLocaleString()}`}
          </span>
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`nc-OtherGoodsCardH group relative w-[700px] bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="OtherGoodsCardH"
    >
      <div className="flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      />
    </div>
  );
};

export default OtherGoodsCardH;
