/* eslint-disable no-unused-vars */
import { firebaseConfig } from "./fiebase.config";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import axios from "axios";
import { setCookie } from "utils/cookies";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth();
auth.languageCode = "en";

onAuthStateChanged(auth, user => {
  if (user && window.location.href.includes("/signup") === true) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
  }
  if (window.location.href.includes("/signup") === false) {
    window.sessionStorage.removeItem("uid");
    window.sessionStorage.removeItem("email");
    window.sessionStorage.removeItem("providerId");
    // User is signed out
    // ...
  }
});

export function signInWithGoogle() {
  window.sessionStorage.clear();
  signInWithPopup(auth, provider)
    .then(result => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/Users/CheckSNSUser`, {
          email: String(user.email),
          snsUid: String(user.uid),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then(response => {
          if (response.data.status === 400) {
            axios
              .post(`${process.env.REACT_APP_BASE_URL}/Users/login`, {
                lngCd: "en",
                snsTypeCd: "1",
                email: String(user.email),
                snsUid: String(user.uid),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              })
              .then(response => {
                setCookie("access-token", response.data.data["key"]);
                setCookie("refresh-token", response.data.data["subKey"]);
                setCookie("user-id", response.data.data.userId);
                setCookie("type", "sns-user");
                window.sessionStorage.setItem("uid", user.uid);
                window.sessionStorage.setItem("email", user.email);
                window.sessionStorage.setItem(
                  "providerId",
                  user.providerData[0].providerId
                );
                window.location.replace("/");
              });
          } else if (response.data.status === 200) {
            alert("First time, Please enter information.");
            window.location.replace("/signup");
            window.sessionStorage.setItem("uid", user.uid);
            window.sessionStorage.setItem("email", user.email);
            window.sessionStorage.setItem(
              "providerId",
              user.providerData[0].providerId
            );
          } else {
            alert("Service Error");
            return;
          }
        });
    })
    .catch(error => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}
