import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import NextPrev from "shared/NextPrev/NextPrev";
import { useIntl } from "react-intl";
import cars from "images/thumbnail/carThumbnail.png";
import realEstate from "images/thumbnail/realEstateThumbnail.png";
import appliance from "images/thumbnail/applianceThumbnail.png";
import fashion from "images/thumbnail/fashionThumbnail.png";
import hobby from "images/thumbnail/hobbiesThumbnail.png";
import otherGoods from "images/thumbnail/otherGoodsThumbnail.png";

export interface SectionSliderCategoryProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType;
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  onClick?: () => void;
}
const SectionSliderCategory: FC<SectionSliderCategoryProps> = ({
  heading,
  subHeading,
  className,
  itemClassName,
  itemPerRow = 5,
  categoryCardType,
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + uniqueClassName;
  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS, itemPerRow]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  function setPrdId(e: any) {
    window.sessionStorage.setItem("other-goods-id", String(e));
  }
  const intl = useIntl();
  const carsIntl = intl.formatMessage({ id: "CARS" });
  const realEstateIntl = intl.formatMessage({ id: "REAL_ESTATE" });
  const appliancesIntl = intl.formatMessage({ id: "APPLIANCES" });
  const fashionIntl = intl.formatMessage({ id: "FASHION" });
  const hobbiesIntl = intl.formatMessage({ id: "HOBBIES" });
  const otherGoodsIntl = intl.formatMessage({ id: "OTHERS" });

  const SectionCategory: TaxonomyType[] = [
    {
      id: "1",
      href: "/listing-other-goods-map",
      name: otherGoodsIntl,
      taxonomy: "category",
      thumbnail: otherGoods,
    },
    {
      id: "2",
      href: "/listing-other-goods-map",
      name: fashionIntl,
      taxonomy: "category",
      thumbnail: fashion,
    },
    {
      id: "2",
      href: "/listing-other-goods-map",
      name: hobbiesIntl,
      taxonomy: "category",
      thumbnail: hobby,
    },
    {
      id: "2",
      href: "/listing-other-goods-map",
      name: appliancesIntl,
      taxonomy: "category",
      thumbnail: appliance,
    },
    {
      id: "1",
      href: "/listing-car-map",
      name: carsIntl,
      taxonomy: "category",
      thumbnail: cars,
    },
    {
      id: "2",
      href: "/listing-real-estate-map",
      name: realEstateIntl,
      taxonomy: "category",
      thumbnail: realEstate,
    },
  ];
  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides">
            {SectionCategory?.map(item => (
              <div
                key={item.name}
                className={`glide__slide ${itemClassName}`}
                onClick={() => {
                  setPrdId(item.id);
                }}
              >
                <CardCategory3 data={item} />
              </div>
            ))}
          </div>
        </div>
        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderCategory;
