import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { PrdList, TaxonomyType } from "data/types";
import CardCategory4 from "components/CardCategory4/CardCategory4";

export interface SectionSliderPopularProductProps {
  className?: string;
  data?: PrdList[];
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType;
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  onClick?: () => void;
}
const SectionSliderPopularProduct: FC<SectionSliderPopularProductProps> = ({
  heading,
  subHeading,
  className,
  itemClassName,
  itemPerRow = 5,
  categoryCardType,
  sliderStyle = "style1",
  uniqueClassName,
  data,
}) => {
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + uniqueClassName;
  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      dragThreshold: false,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS, itemPerRow]);
  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS, data]);

  function setPrdId(e: any) {
    window.sessionStorage.setItem("other-goods-id", String(e));
  }
  return (
    <div className={``}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides">
            {data?.map(item => (
              <div
                key={item.prdInfoId}
                className={`glide__slide ${itemClassName}`}
                onClick={() => {
                  setPrdId(item.prdInfoId);
                }}
              >
                <CardCategory4 data={item} />
              </div>
            ))}
          </div>
        </div>
        {/* {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )} */}
      </div>
    </div>
  );
};

export default SectionSliderPopularProduct;
