import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { debounce } from "ts-debounce";
import facebookSvg from "images/Facebook.svg";
import AppleSvg from "images/Apple.svg";
import googleSvg from "images/Google.svg";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Badge from "shared/Badge/Badge";
import Checkbox from "shared/Checkbox/Checkbox";
import { signInWithGoogle } from "../../firebase/firebase.utils.googleauth";
import { signInWithFacebook } from "../../firebase/firebase.utils.facebookauth";
import { setCookie } from "utils/cookies";
// import { getCookie } from "utils/cookies";

export interface PageSignUpProps {
  href?: string;
  className?: string;
  userInput?: (e: any) => void;
  onClick?: (e: any) => void;
}
axios.defaults.withCredentials = false;

const validEmail = (e: string) => {
  const resurt =
    /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  return resurt.test(String(e).toLowerCase());
};

const validName = (e: string) => {
  const result = /[~!@#$%^&*()_+|<>?:{}]|[0-9]/;
  return result.test(String(e).toLocaleLowerCase());
};

const validNickName = (e: string) => {
  const result = /[~!@#$%^&*()_+|<>?:{}]/;
  return result.test(String(e).toLocaleLowerCase());
};

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  //   onClick: signInWithFacebook,
  // },
  // {
  //   name: "Continue with Apple",
  //   href: "#",
  //   icon: AppleSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
    onClick: signInWithGoogle,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [checkNickStatusCode, setCheckNickStatusCode] = useState({
    status: 0,
    data: "",
  });
  const [emailCode, setInputEmailCode] = useState("");
  const [checkEmailCode, setCheckEmailCode] = useState("");
  const [emailCheckBadge, setEmailCheckBadge] = useState(false);
  const [nickNmCheck, setNickNameCheck] = useState(false);
  const [confirmPW, setConfirmPW] = useState();
  const [user, setUser] = useState({
    reqData: {
      lngCd: "en",
      typeCd: "",
      firstName: "",
      lastName: "",
      nickName: "",
      email: "",
      password: "",
      snsuid: "",
      terms: false,
      privacy: false,
    },
  });
  const [signUpStatus, setSignUpStatus] = useState({
    status: 0,
  });
  const {
    firstName,
    lastName,
    nickName,
    email,
    password,
    terms,
    privacy,
    typeCd,
  } = user.reqData;

  const uid = window.sessionStorage.getItem("uid");
  const snsEmail = window.sessionStorage.getItem("email");
  const providerId = String(window.sessionStorage.getItem("providerId"));

  useEffect(() => {
    if (
      window.location.href.includes("/signup") &&
      window.sessionStorage.getItem("user-id") !== null
    ) {
      alert("Already Logged");
      window.location.href = "/";
      return;
    }
    setEmailCheckBadge(false);
    if (uid !== null) {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          email: String(window.sessionStorage.getItem("email")),
          snsuid: String(window.sessionStorage.getItem("uid")),
        },
      }));
    }
    if (providerId.includes("google.com")) {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          typeCd: "1",
        },
      }));
    } else if (providerId.includes("apple.com")) {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          typeCd: "2",
        },
      }));
    } else {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          typeCd: "0",
        },
      }));
    }
  }, [uid, providerId]);

  function userInputFirstName(e: any) {
    setUser(el => ({
      ...el,
      reqData: {
        ...el.reqData,
        firstName: e.target.value,
      },
    }));
  }

  function userInputLastName(e: any) {
    setUser(el => ({
      ...el,
      reqData: {
        ...el.reqData,
        lastName: e.target.value,
      },
    }));
  }
  function userInputNickName(e: any) {
    setUser(el => ({
      ...el,
      reqData: {
        ...el.reqData,
        nickName: e.target.value,
      },
    }));
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Users/CheckNick`, {
        lngcd: "en",
        nicknm: nickName,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.status === 200) {
          setNickNameCheck(true);
        } else {
          setNickNameCheck(false);
        }
        setCheckNickStatusCode(response.data);
      })
      .catch(error => console.log(error));
  }

  function userInputEmail(e: any) {
    setUser(el => ({
      ...el,
      reqData: {
        ...el.reqData,
        email: e.target.value,
      },
    }));
    if (!validEmail(email)) {
      setEmailCheckBadge(false);
    }
  }

  function userInputEmailCode(e: any) {
    setInputEmailCode(e.target.value);
  }

  function sendEmailCode() {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Users/CheckEmail`, {
        lngcd: "en",
        email: email,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.status === 400) {
          alert("Email Alredy exists");
          return;
        } else if (response.data.status === 200) {
          alert("Please check the code sent to your email");
          setCheckEmailCode(response.data.code);
          setEmailCheckBadge(true);
        } else {
          setEmailCheckBadge(false);
          return;
        }
      })
      .then(error => console.log(error));
  }

  function userInputPassword(e: any) {
    setUser(el => ({
      ...el,
      reqData: {
        ...el.reqData,
        password: e.target.value,
      },
    }));
  }

  function userInputConfirmPassword(e: any) {
    setConfirmPW(e.target.value);
  }

  function termsChecked() {
    if (!terms) {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          terms: true,
        },
      }));
    } else {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          terms: false,
        },
      }));
    }
  }

  function privacyChecked() {
    if (!privacy) {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          privacy: true,
        },
      }));
    } else {
      setUser(el => ({
        ...el,
        reqData: {
          ...el.reqData,
          privacy: false,
        },
      }));
    }
  }

  function userSignUpButn() {
    if (validName(firstName)) {
      alert("Unavailable first name");
      return;
    } else if (validName(lastName)) {
      alert("Unavailable last name");
      return;
    } else if (validNickName(nickName)) {
      alert("Unavailable nick name");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Users/SetUserInfo`, {
        lngCd: "en",
        typeCd: typeCd,
        firstNm: firstName,
        lastNm: lastName,
        nickNm: nickName,
        email: email,
        password: password,
        snsUid: uid,
        terms: terms,
        privacy: privacy,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(response => {
        if (response.data.status === 200) {
          alert(`Welcome ${user.reqData.firstName}`);
          setSignUpStatus(response.data);
          setCookie("user-id", response.data.data.userId);
          window.location.replace("/login");
        } else {
          alert("Service Error");
          return;
        }
      })
      .catch(error => console.log(error));
  }
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <span className="block text-center text-neutral-700 dark:text-neutral-300 mb-5">
          Already have an account? {` `}
          <Link
            className="text-neutral-900"
            to="/login"
            onClick={() => window.sessionStorage.clear()}
          >
            <u>Sign in</u>
          </Link>
        </span>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                onClick={item.onClick}
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                className="mt-1"
                userInput={e => userInputFirstName(e)}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                className="mt-1"
                userInput={e => userInputLastName(e)}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                NickName &nbsp;
                {nickName.length > 0 ? (
                  nickNmCheck ? (
                    <Badge color="green" name="Available" />
                  ) : (
                    <Badge color="red" name="Unavailable" />
                  )
                ) : (
                  <span />
                )}
              </span>
              <Input
                type="text"
                className="mt-1"
                userInput={debounce(userInputNickName, 1000)}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder={"example@example.com"}
                className="mt-1"
                userInput={debounce(userInputEmail, 1000)}
                defaultValue={uid !== null ? String(snsEmail) : ""}
                disabled={uid !== null ? true : false}
              />
              {validEmail(email) && uid === null ? (
                <span>
                  <ButtonPrimary
                    className="mt-1"
                    type="button"
                    onClick={sendEmailCode}
                  >
                    Send Code &nbsp;
                    {emailCheckBadge ? (
                      emailCode === checkEmailCode ? (
                        <Badge color="green" name="Available" />
                      ) : (
                        <Badge color="gray" name="Pending" />
                      )
                    ) : (
                      <span />
                    )}
                  </ButtonPrimary>
                  <Input
                    type="password"
                    className="mt-1"
                    placeholder="Code"
                    userInput={debounce(userInputEmailCode, 1000)}
                  />
                </span>
              ) : (
                ""
              )}
            </label>
            {uid === null ? (
              <div>
                <div className="mb-5">
                  <label className="block">
                    <span className="flex items-center text-neutral-800 dark:text-neutral-200">
                      Password &nbsp;
                      {password.length > 0 ? (
                        confirmPW === password ? (
                          <Badge color="green" name="Available" />
                        ) : (
                          ""
                        )
                      ) : (
                        <span />
                      )}
                    </span>
                    <Input
                      type="password"
                      className="mt-1"
                      userInput={e => userInputPassword(e)}
                    />
                  </label>
                </div>
                <div>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Confirm Password &nbsp;
                      {password.length > 0 ? (
                        confirmPW === password ? (
                          <Badge color="green" name="Available" />
                        ) : (
                          <Badge color="red" name="Unavailable" />
                        )
                      ) : (
                        <span />
                      )}
                    </span>
                    <Input
                      type="password"
                      className="mt-1"
                      userInput={e => userInputConfirmPassword(e)}
                      disabled={password.length > 0 ? false : true}
                    />
                  </label>
                </div>
              </div>
            ) : (
              ""
            )}
            <span className="flex mt-5">
              <Checkbox name="terms" onChange={() => termsChecked()} />
              &nbsp; I agree to the NE:ME &nbsp;{" "}
              <Link to="/terms-n-conditions" target={"_blank"}>
                <u>Terms of Use</u>
              </Link>
            </span>
            <span className="flex mb-5">
              <Checkbox name="privacy" onChange={() => privacyChecked()} />
              &nbsp; I agree to the NE:ME &nbsp;{" "}
              <Link to="/privacy-policy-guideline" target={"_blank"}>
                <u>Privacy Policy</u>
              </Link>
            </span>
            <ButtonPrimary
              type="button"
              href={signUpStatus.status === 200 ? "/login" : ""}
              disabled={
                firstName.length > 0 &&
                lastName.length > 0 &&
                checkNickStatusCode.status === 200 &&
                //validEmail(email) &&
                //emailCode === checkEmail.data.code &&
                //password === confirmPW &&
                terms &&
                privacy
                  ? false
                  : true
              }
              onClick={() => userSignUpButn()}
            >
              Continue
            </ButtonPrimary>
          </form>
          {/* ==== */}
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
